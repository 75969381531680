.homepage {
   h1 {
      margin-bottom: 2rem;
      font-weight: 100;
      text-align: left;
      color: $ljubicasta;
   }

   .search-container {
      width: 100rem;
      margin: auto;
   }
}
