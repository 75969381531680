.package-pages,
.borrow-package {
    label,
    select {
        font-size: 1.6rem;
        color: black;
        font-weight: 700;
    }
    select {
        font-weight: 400;
        height: 4rem;
    }
    // select.form-control {
    //     height: 20rem;
    // }
}
