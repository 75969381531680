.login-page {
    * {
        font-size: 1.6rem;
    }

    h1 {
        margin-bottom: 3rem;
    }

    label {
        font-weight: 700;
    }

    input {
        border-radius: 0.3rem;
        padding: 1rem;

        &::placeholder {
            opacity: 0.5;
        }
    }
}
