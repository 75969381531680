.student-data {
   position: relative;

   .borrow-type {
      @include flex(column, center, center);
      width: 10rem;
      height: 10rem;
      color: black;
      cursor: pointer;
      font-weight: 700;

      position: absolute;
      top: 0;
      right: 0;
   }
}
