.navbar {
    @include flex(row, space-between, center);
    background: #111;
    box-shadow: 0 0 0.25rem #111;
    height: $navbar-height;
    left: 0;
    padding: 0 2rem;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;

    .nav {
        @include flex(row, center, center);
    }

    .logo {
        @include flex(row, center, center);
        //font-family: 'Luckiest Guy', cursive;
        font-size: 2.5rem;
        color: $narancasta;
        text-shadow: 0 0 0.25rem $narancasta;
        div {
            margin-left: 0.5rem;

            span {
                color: $narancasta;
                display: block;
                margin-bottom: -1.5rem;
            }
        }
        img {
            height: 4.5rem;
        }
    }

    .user {
        font-weight: 700;
        color: $narancasta;
    }
}
