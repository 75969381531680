.add-book {
   input {
      font-size: 1.6rem;
      padding: 2.5rem;
      &::placeholder {
         opacity: 0.5;
      }
   }

   label {
      font-size: 1.6rem;
      color: black;
      font-weight: 700;
   }

   button {
      padding: 2.5rem;
      color: #fff;
      background-color: $ljubicasta;
      border-color: $ljubicasta;
      transition: all 0.5s ease;
      height: 3rem;
      width: 100%;
      line-height: 0.2rem;
      display: inline-block;
      cursor: pointer;
      font-weight: 700;

      &:hover:enabled,
      &:focus {
         color: #fff;
         background-color: #333;
         border-color: #333;
      }
      &:disabled {
         opacity: 0.25;
         cursor: auto;
         box-shadow: none;
      }
   }
}
