.footer {
    @include flex(row, center, center);
    background: #111;
    bottom: 0;
    box-shadow: 0 0 0.25rem #111;
    height: 7rem;
    left: 0;
    position: fixed;
    width: 100%;
    img {
        height: 1.4rem;
    }
}
