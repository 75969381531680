.preview-package-books,
.package-show-books {
   th {
      text-transform: uppercase;
      font-weight: 900;
      color: $ljubicasta;
   }

   td,
   th {
      padding: 0.5rem 1rem;
   }

   thead {
      tr {
         th {
            border: none;
            border-bottom: 0.4rem solid #000;
         }
      }
   }

   tbody tr td.align-middle:first-child div {
      width: 30rem;
      @include threeDots();
   }
}
