.search-result {
   thead {
      tr {
         th {
            text-transform: uppercase;
            font-weight: 900;
            color: $ljubicasta;
            border: none;
            border-bottom: 0.4rem solid #000;
            &:nth-child(1),
            &:nth-child(2) {
               width: 30rem;
            }
            &:nth-child(3),
            &:nth-child(4) {
               width: 10rem;
            }
         }
      }
   }

   tbody {
      tr {
         transition: all 0.25s ease;
         &:hover {
            color: white;
            background-color: $ljubicasta;
            cursor: pointer;
         }

         td:nth-child(1),
         td:nth-child(2) {
            font-weight: 700;
         }
      }
   }
}
