.custom-alert {
    @include flex(row, center, center);
    background-color: rgba($color: #000000, $alpha: 0.5);
    color: #333;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;

    .custom-alert-popup {
        @include flex(column, space-between, flex-start);
        background-color: #fff;
        border-radius: 0.25rem;
        box-shadow: 0 0 1rem #444;
        padding: 1.5rem;
        width: 60rem;

        .custom-alert-title,
        .custom-alert-content,
        .custom-alert-footer {
            width: 100%;
        }

        .custom-alert-title {
            @include flex(row, flex-start, center);
            background-color: #333;
            border-radius: 0.2rem;
            color: #fff;
            font-size: 1.6rem;
            font-weight: 700;
            height: 4rem;
            margin-bottom: 2rem;
            padding-left: 1rem;
            text-transform: uppercase;
        }

        select {
            font-size: 1.4rem;
            border: 1px solid #ccc;
            border-radius: 0.3rem;
            padding: 0.25rem;

            &:focus {
                outline: none;
            }
        }

        table {
            tr {
                height: 3.5rem;
            }
            td:last-of-type {
                font-weight: 700;
            }
        }

        .custom-alert-footer {
            @include flex(row, flex-end, center);
            .custom-alert-btn {
                border-radius: 0.3rem;
                border: 1px solid;
                color: white;
                display: inline-block;
                font-weight: 500;
                margin-left: 0.5rem;
                padding: 1rem;
                width: 12rem;
            }

            .btnPrimary {
                background-color: $ljubicasta;

                &:focus {
                    outline: none;
                    border: none;
                    box-shadow: 0 0 0.5rem $ljubicasta;
                }
            }

            .btnSecondary {
                background-color: #333;
            }
        }
    }
}
