.search {
   margin-bottom: 2rem;
   input {
      &:focus,
      &:active {
         outline: none;
      }

      &::placeholder {
         opacity: 0.25;
      }
   }
}

.search-text {
   width: 100%;
   border-radius: 5px;
   padding: 1rem 1.5rem;
   font-size: 3rem;
   border: 1px solid #ccc;
   margin: 0 0.2rem;
   transition: box-shadow 0.25s ease;
   color: #666;
   box-shadow: 0 0 1rem #fff;

   &:focus {
      box-shadow: 0 0 3rem #fff;
   }
}
