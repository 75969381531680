.navigation-link {
   position: relative;
   margin: 0 1rem;
   padding: 0.5rem;

   a {
      color: #fff;
      display: inline-block;
      font-size: 2.2rem;
      font-weight: 500;
      margin: 0 3rem;
      position: relative;
      text-transform: uppercase;
      transition: all 0.2s ease;
      z-index: 10;

      &:hover {
         text-shadow: 0 0 1rem $narancasta;
         text-decoration: none;
      }
   }

   .background {
      background: $narancasta;
      border-radius: 0.25rem;
      box-shadow: 0 0 0.5rem $narancasta;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 5;
   }
}
