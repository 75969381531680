.table-books {
   margin-bottom: 1rem;

   .empty {
      color: #666;
      font-size: 2rem;
      display: block;
      text-align: center;
      font-weight: 100;
   }

   td,
   th {
      padding: 0.5rem 1rem;
   }

   td,
   th {
      font-weight: 500;
      &:first-child {
         div {
            font-weight: 900;
            width: 50rem;
         }
      }
      &:nth-child(2) {
         width: 10rem;
      }
      &:nth-child(3) {
         div {
            width: 15rem;
         }
      }
      &:last-child {
         width: 12rem;
         text-align: center;
      }

      button,
      .dismiss {
         width: 10rem;
         font-weight: 500;
         color: #fff;
         background-color: $ljubicasta;
         border-color: $ljubicasta;
         transition: all 0.5s ease;

         &:hover,
         &:focus {
            text-decoration: none;
            color: #fff;
            background-color: #333;
            border-color: #333;
         }
      }
      .dismiss {
         background-color: $narancasta;
         border-color: $narancasta;
         &:hover,
         &:focus {
            text-decoration: none;
            color: #fff;
            background-color: #333;
            border-color: #333;
         }
      }
   }

   th {
      text-transform: uppercase;
      font-weight: 900;
      color: $ljubicasta;
   }

   thead {
      tr {
         th {
            border: none;
            border-bottom: 0.4rem solid #000;
         }
      }
   }

   tbody tr td.align-middle div {
      @include threeDots();
   }
}
