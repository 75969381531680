.student-information {
    color: #333;
    margin-bottom: 5rem;
    h1 {
        color: $ljubicasta;
        text-shadow: 0 0 0.2rem $ljubicasta;
        text-align: left;
        font-weight: 100;
        margin-bottom: 0;
    }
    td:first-child {
        width: 5rem;
        font-weight: 700;
    }
    td:last-child {
        padding-left: 1rem;
    }
}
