$color: #ddd;
//$background: linear-gradient(#222, #000);
$background: #31343a;
$content-background: #eee;
$narancasta: #e1700e;
$ljubicasta: #c30e60;
$navbar-height: 8rem;

@mixin threeDots {
   text-overflow: ellipsis;
   overflow: hidden;
   white-space: nowrap;
}

@mixin flex($flexDirection, $mainAxis, $secondaryAxis) {
   display: flex;
   flex-direction: $flexDirection;
   justify-content: $mainAxis;
   align-items: $secondaryAxis;
}

* {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
}

html {
   font-size: 62.5%;
   background: black;
   min-height: 100vh;
   overflow: auto;
}

.app {
   font-family: 'Roboto Condensed', sans-serif;
   background: $background;
   color: $color;
   font-size: 1.6rem;
   min-height: 100vh;
   //padding-top: ($navbar-height + 5rem);

   h1 {
      font-size: 5rem;
      text-align: center;
   }

   header,
   .content-container {
      width: 100rem;
      // min-height: 71rem;

      margin: auto;
      overflow: auto;
      background: $content-background;
      border-radius: 0.2rem;
      font-size: 1.4rem;
      padding: 4rem;
      box-shadow: 0 0 1rem $content-background;
   }
}

.center {
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
}

.btn {
   font-size: 1.4rem;

   &:focus {
      outline: none;
      box-shadow: none;
   }
}

h1 {
   color: #c30e60;
   text-shadow: 0 0 0.2rem #c30e60;
   text-align: left;
   font-weight: 100;
   margin-bottom: 0;
}

/*
    SCROLLBAR
*/

*::-webkit-scrollbar {
   width: 5px;
   height: 100%;
}

*::-webkit-scrollbar-track {
   background: transparent;
}

*::-webkit-scrollbar-thumb {
   background-color: transparent;
   border-radius: 20px;
   border: transparent;
}

/*
   PAGINATION
*/
.page-item.active .page-link {
   z-index: 3;
   color: #fff;
   background-color: $ljubicasta;
   border-color: $ljubicasta;
}
.pagination-sm .page-link {
   padding: 0.25rem 0.5rem;
   font-size: 1.2rem;
   line-height: 1.5;
   color: $ljubicasta;
   font-weight: 700;
}
.pagination-sm .page-item:first-child .page-link {
   border-top-left-radius: 0;
   border-bottom-left-radius: 0;
}

/*
    ---
*/

.category-container {
   @include flex(row, space-between, center);
   span[class] {
      cursor: pointer;
   }
}

.myToast {
   font-size: 1.2rem;
   font-weight: 700;
}

select.form-control:focus,
select.form-control:active {
   outline: none;
   box-shadow: none;
   border-color: #ced4da;
}
